code
	font-family: ui-monospace, monospace
	line-break: anywhere
	font-size: smaller

// Inline code
code.highlighter-rouge
	padding: 2px
	border-radius: 5px
	background-color: var(--syntax-background)

// Code blocks
.highlight, .highlighter-rouge
	padding: 5px
	border-radius: 5px

	// MARK: General
	pre, .hll
		white-space: pre-wrap
		word-break: keep-all
		tab-size: 2
		background-color: var(--syntax-background)

	// Error
	.err
		color: var(--syntax-punctuation)
		background-color: var(--syntax-error-background)

	// Generic.Emph
	.ge
		font-style: italic

	// Generic.Strong
	.gs
		font-weight: bold


	// MARK: Comment
	// Comment, Comment.Multiline, Comment.Preproc, Comment.Single, Comment.Special
	.c, .cm, .cp, .c1, .cs
		color: var(--syntax-comment)


	// MARK: Keyword
	// Keyword, Keyword.Constant, Keyword.Declaration, Keyword.Pseudo, Keyword.Reserved, Keyword.Type
	.k, .kc, .kd, .kp, .kr, .kt
		color: var(--syntax-keyword)


	// MARK: Name
	// Name, Name.Builtin, Name.Entity, Name.Label, Name.Namespace, Name.Property, Name.Variable, Name.Builtin.Pseudo, Name.Variable.Class, Name.Variable.Global Name.Variable.Instance, Punctuation
	.n, .nb, .ni, .nl, .nn, .py, .nv, .bp, .vc, .vg, .vi, .p
		color: var(--syntax-punctuation)

	// Name.Attribute, Name.Class, Name.Decorator, Name.Exception, Name.Function, Name.Other
	.na, .nc, .nd, .ne, .nf, .nx
		color: var(--syntax-name-0)

	// Name.Constant
	.no
		color: var(--syntax-name-1)


	// MARK: Operator
	// Operator, Operator.Word, Keyword.Namespace, Name.Tag
	.o, .ow, .kn, .nt
		color: var(--syntax-operator)


	// MARK: Literal
	// Literal, Literal.Number, Literal.Number.Float, Literal.Number.Hex, Literal.Number.Integer, Literal.Number.Oct, Literal.Number.Integer.Long, Literal.String.Escape
	.l, .m, .mf, .mh, .mi, .mo, .il, .se
		color: var(--syntax-literal-number)

	// Literal.String, Literal.String.Backtick, Literal.String.Char, Literal.String.Doc, Literal.String.Double, Literal.String.Heredoc, Literal.String.Interpol, Literal.String.Other, Literal.String.Regex, Literal.String.Single, Literal.String.Symbol, Literal.Date
	.s, .sb, .sc, .sd, .s2, .sh, .si, .sx, .sr, .s1, .ss, .ld
		color: var(--syntax-literal-string)
